import api from "@/request/api";
// import Vue from 'vue'

export default {
    find(id) {
       return  api.get('channel/' + id);
    },
    save(data) {
        return api.post('channel/save', data);
    },
    findAll(query) {
        return api.get('channel/list', {query:{query}});
    },
    getDataList(params={}) {
        return api.getWithToken('channel/data/list',{params:params});
    },
    getDataPhoneList(params={}) {
        return api.getWithToken('channel/data/phone',{params:params});
    },
    getDataListTotal(params={}) {
        return api.getWithToken('channel/data/list/total',{params:params});
    },
    getDataChart() {
        return api.get('channel/data/chart');
    }
}
