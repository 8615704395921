<template>
    <div class="thePage" v-if="pageData.iTotalRecords > 0">
<!--        <span>第{{pageData.currentPage}}页/{{pageData.iTotalRecords}}条数据</span>-->
<!--        <el-pagination-->
<!--                background-->
<!--                @current-change="pageChange"-->
<!--                :page-size="pageData.pageSize"-->
<!--                :current-page="pageData.currentPage"-->
<!--                layout="prev, pager, next, jumper"-->
<!--                :total="pageData.iTotalRecords">-->
<!--        </el-pagination>-->
        <el-pagination
                background
                @size-change="sizeChange"
                @current-change="pageChange"
                :page-size="pageData.pageSize"
                :current-page="pageData.pageNumber+1"
                :page-sizes="[pageData.pageSize,20, 50, 100, 200]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pageData.iTotalRecords">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        props: {
            pageData: {
                type: Object,
                default: null
            }
        },
        methods: {
            // 换页功能
            pageChange(nowPage) {
                this.$emit('pageIndexChan', nowPage)
            },
            sizeChange(nowSize){
                this.$emit('pageSizeChan',nowSize)
            }
        }
    }

</script>

<style scoped>
    .thePage {
        display: flex;
        align-items: center;
        float: right;
        padding: 24px 0 24px;
        font-size: 14px;
    }

</style>
