<template>
  <div class="console">
    <h5>数据中心</h5>

    <div class="main mt-3">
      <form action="" class="form-inline" @submit="onSubmit">
        <div class="form-row align-items-center">
          <div class="col-auto">
          <el-date-picker
                  v-model="form.startTime"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期">
          </el-date-picker>
          </div>
          <div class="col-auto">
            到
          </div>
          <div class="col-auto">
            <el-date-picker
                    v-model="form.endTime"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="col-auto">
            <button type="submit" class="btn btn-primary btn-sm">查询</button>
          </div>
        </div>
      </form>
      <div class="mt-3">
        彩票数量（张）：{{totalData.buyCount}} ，剩余（张）：{{totalData.leftCount}}， 消耗（元）：{{ totalData.spend }}，领取（张）：{{ totalData.drawCount }}，刮票（张）：{{ totalData.scratchCount }}，兑奖（张）：{{ totalData.redeemCount }}
      </div>
      <el-table
              :data="tableData" stripe
              style="width: 100%">
        <el-table-column
                prop="statDate"
                label="日期"
                :formatter="dateFormatter"
        ></el-table-column>
          <el-table-column
                  prop="name"
                  label="彩票名称"
          >
        </el-table-column>
        <el-table-column
                prop="spend"
                label="消耗（元）">
        </el-table-column>
        <el-table-column prop="drawCount"
                label="领取（张）">

        </el-table-column>
        <el-table-column prop="scratchCount"
                label="刮奖（张）">
        </el-table-column>
        <el-table-column prop="redeemCount"
                         label="兑奖（张）">
<!--          <template slot-scope="scope">-->
<!--            <el-link type="primary" @click="queryPhone(scope.row)" target="_blank">-->
<!--              {{scope.row.redeemCount}}-->
<!--            </el-link>-->
<!--          </template>-->
        </el-table-column>
        <el-table-column prop="surplusStock"
                         label="库存（张）">
        </el-table-column>
      </el-table>
      <Paging :pageData="pageData" @pageSizeChan="pageSizeChan" @pageIndexChan="pageIndexChan" />
    </div>


  </div>
</template>
<script>
  import Channel from "@/model/Channel"
  import Paging from "@/components/Paging";

  export default {
  components: {
    Paging
  },
  data() {
    return {
      form: {
        startTime: '',
        endTime: '',
        pageSize: 20,
        pageNumber: 0,
          channelId:JSON.parse(localStorage.getItem("user")).channelId
      },

      tableData: [],
      pageData: {
        pageSize: 20,
        pageNumber: 0,
        iTotalRecords: 0
      },
        totalData:{
            spend:0,
            drawCount:0,
            scratchCount:0,
            redeemCount:0,
            buyCount:0,
            leftCount:0

        }
    }
  },
    mounted() {
      this.init(this.form);
      this.total(this.form);
    },
  methods: {
    queryPhone(row){
        console.log(row);
      this.$router.push({path:'/console/phone'})
    },
    init(params) {
    //   User.find().then(response => {
    //     if(response.data.code === 0) {
    //       // console.log(response.data.data);
    //       this.user = response.data.data;
    //     }
    //   });

    Channel.getDataList(params).then(response => {
      if(response.data.code === 0) {
        console.log(response.data.data);

        this.tableData = response.data.data.iData;
        this.pageData={
          pageNumber:response.data.data.currentPage,
          pageSize:response.data.data.pageSize,
          iTotalRecords:response.data.data.iTotalRecords
        }
        // console.log(this.pageData);
      }else{
        this.$bvToast.toast(response.data.msg);

      }
    })

    },
      total(params){
          Channel.getDataListTotal(params).then(response=>{
              if(response.data.code === 0) {
                  // console.log(response.data);

                  this.totalData = response.data.data;
                  console.log(this.totalData)
                  // console.log(this.pageData);
              }else{
                  this.$bvToast.toast(response.data.msg);

              }
          })
      },

    // 分页功能
    pageIndexChan(pg) {
      this.form.pageNumber = pg-1;
        console.log(this.form)
      this.init(this.form);
    },
    pageSizeChan(size){
      this.form.pageSize=size;
      this.init(this.form);
    },

    onSubmit(e) {
      e.preventDefault();
      this.form.pageNumber=0;
      this.init(this.form);
      this.total(this.form);
    },

    dateFormatter(row,column,value){
      return value.substring(0,10)
    }
  }
}
</script>
